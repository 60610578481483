import React from "react";
import styles from './styles.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";

function Contatc() {

    return(
        <>
        <section className={styles.contactWrapper}>
            <h4 className={styles.h4}>CONTATO</h4>
            <h3 className={styles.h3}>Mande uma mensagem! 😸</h3>
            <section className={styles.contacts}>
                <div className={styles.contact}>
                    <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                    <div className={styles.iDontKnow}>
                        <p>E-mail</p>
                        <p>01ramazza@gmail.com</p>
                    </div>
                </div>
                <div className={styles.contact}>
                    <FontAwesomeIcon className={styles.icon} icon={faPhone} />
                    <div className={styles.iDontKnow}>
                        <p>Telefone</p>
                        <p>(16) 99239-7477</p>
                    </div>
                </div>
            </section>
        </section>
        </>
    );

}

export default Contatc;