import React from "react";
import style from './styles.module.css';
import Tech from "../../tech/tech";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function Project(props) {

    const windowWidth = window.innerWidth
    let flexDirection = props.reverse

    if(windowWidth <= 415){
        flexDirection = 'column'
    }

    return(
        <>
            <div className={style.projectWrapper} style={{flexDirection: flexDirection}}>
                <aside className={style.aside}>
                    <img className={style.img} src={require(`../../../assets/${props.pathName}_preview.gif`)} alt="Project Preview" />
                </aside>
                <main className={style.main}>
                    <h2 className={style.h2}>{props.name}</h2>
                    <p className={style.p}>{props.description}</p>
                    <div className={style.techStack}>
                        <Tech name={props.tech1}/>
                        <Tech name={props.tech2}/>
                        <Tech name={props.tech3}/>
                    </div>
                    <div className={style.contact}>
                        <a href={props.github} target="blank">
                            <p className={style.name}>Code</p>
                            <FontAwesomeIcon className={style.icon} icon={faGithub}/>
                        </a>
                        <a href={props.code} target="blank">
                            <p className={style.name}>Live Demo</p>
                            <FontAwesomeIcon className={style.icon} icon={faArrowUpRightFromSquare}/>
                        </a>
                    </div>
                </main>
            </div>
        </>
    );

}

export default Project;