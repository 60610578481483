import "./App.module.css";
import About from "./components/about/about";
import Contatc from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Hero from "./components/hero/hero";
import Projects from "./components/projects_/projects";

function App() {

  return (
    <>
      <Header />
      <Hero />
      <About />
      <Projects />
      <Contatc />
      <Footer />
    </>
  );
}

export default App;
