import React from 'react';
import styles from './styles.module.css';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TechStack from './tech_stack/tech_stack';

function Hero() {

    return(
        <>
            <div className={styles.heroContainer}>
                <main className={styles.main}>
                    <h1 className={styles.h1}>Desenvolvedor Front-End</h1>
                    <span className={styles.span}>Olá, sou Matheus Ramazza. Um desenvolvedor front-end que é apaixonado por gatos 🐱.</span>
                    <div className={styles.contact}>
                        <a href="https://github.com/Ramazza" target='blank'>
                            <FontAwesomeIcon className={styles.icon} icon={faGithub} />
                        </a>
                        <a href="https://www.linkedin.com/in/matheus-ramazza/" target='blank'>
                            <FontAwesomeIcon className={styles.icon} icon={faLinkedin} />
                        </a>
                    </div>
                    <TechStack />
                </main>
                <aside className={styles.aside}>
                    <div className={styles.selfie}></div>
                </aside>
            </div>
        </>
    );

}

export default Hero;