import React from "react";
import style from './styles.module.css';
import Project from "./project_/project";
import { Element } from "react-scroll";

function Projects() {

    return(
        <>
            <Element name='projects'>
            <div className={style.portfolio}>
                <h3 className={style.h3}>Portfólio</h3>
                <h2 className={style.h2}>Projetos únicos de desenvolvimento</h2>
            </div>
            <Project 
            name='Calculadora do Windows'
            description='Para o meu primeiro "grande" projeto, criei uma réplica funcional da calculadora do windows, com todas as suas funcionalidades básicas. Sendo possível minimizar, maximizar, fechar e também movimentar a calculadora.'
            pathName='calculator'
            github='https://github.com/Ramazza/Windows-Calculator'
            code='https://windows-calculator-chi.vercel.app/'
            tech1='HTML'
            tech2='CSS'
            tech3='Javascript'
            reverse='row'
            />
            <Project 
            name='Calculadora de inventário Steam'
            description='Este projeto React calcula o valor do inventário do Counter-Strike do usuário, integrando-se a uma API para recuperar informações detalhadas sobre os itens. Com componentes reutilizáveis e atualizações eficientes, proporciona uma experiência fluida.'
            pathName='steam'
            github='https://github.com/Ramazza/Steam-Inventory-Calculator'
            code='https://steam-inventory-calculator.vercel.app/'
            tech1='HTML'
            tech2='CSS'
            tech3='React'
            reverse='row-reverse'
            />
            <h2 className={style.p}>Novos projetos em breve...</h2>
            </Element>
        </>
    );

}

export default Projects;