import React from "react";
import style from './styles.module.css'
import { Element } from "react-scroll";

function About() {

    return(
        <>
        <Element name="about">
            <div className={style.aboutWrapper}>
                <aside className={style.aside}>
                    <img className={style.img} src={require('../../assets/about_img.jpg')} alt="" />
                </aside>
                <main className={style.main}>
                    <h3 className={style.h3}>Sobre mim</h3>
                    <h2 className={style.h2}>Um desenvolvedor Front-End dedicado</h2>
                    <p className={style.p}>
                        Sou um desenvolvedor front-end junior, com uma formação em Engenharia da Computação. Durante minha jornada acadêmica, adquiri conhecimentos 
                        sólidos e, desde então, venho aplicando minhas habilidades técnicas em projetos desafiadores e inovadores. Minha experiência abrange o 
                        desenvolvimento de interfaces responsivas e interativas, destacando-me pela proficiência em HTML, CSS e JavaScript, além da integração de 
                        frameworks como React.
                    </p>
                </main>
            </div>
        </Element>
        </>
    );

}

export default About;