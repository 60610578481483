import React from "react";
import style from './styles.module.css'
import Tech from "../../tech/tech";

function TechStack() {

    return(
        <>
            <main className={style.techStackWrapper}>
                <p className={style.p}>Tecnologias: </p>
                <div className={style.techStack}>
                    <Tech name='HTML'/>
                    <Tech name='CSS'/>
                    <Tech name='Javascript'/>
                    <Tech name='React'/>
                </div>
            </main>

        </>
    );

}

export default TechStack;