import React from "react";
import styles from './styles.module.css';

function Tech(prop) {

    const techName = prop.name.toLowerCase() 

    return(
        <>
            <div className={styles.techWrapper}>
                <img className={styles.img} src={require(`../../assets/${techName}.png`)} alt={prop.name} />
                <span className={styles.span}>{prop.name}</span>
            </div>
        </>
    );

}

export default Tech;