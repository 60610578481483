import styles from './styles.module.css'
import { useState } from 'react';
import { Link, animateScroll, scroller  } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Header() {

    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const options = {
        duration: 1000,
        smooth: true,
    };

    const goTop = () => {
        setIsButtonClicked(false);
        animateScroll.scrollToTop(options);
    }

    const goBot = () => {
        setIsButtonClicked(false);
        animateScroll.scrollToBottom(options);
    }
    
    const handleButtonClick = () => {
        setIsButtonClicked(!isButtonClicked);
    };

    const goAbout = () => {

        setIsButtonClicked(false);
        
        setTimeout(() => {
            scroller.scrollTo('about', {
                duration: 1000,
                smooth: true,
                spy: true,
                offset: -60, 
            });
        }, 100)

    }

    const goProjects = () => {

        setIsButtonClicked(false);

        setTimeout(() => {
            scroller.scrollTo('projects', {
                duration: 1000,
                smooth: true,
                spy: true,
                offset: -60, 
            });
        }, 100)

    }

    return(
        <> 
            <div className={isButtonClicked ? styles.clicked : styles.notClicked}>
                <div className={isButtonClicked ? styles.navMain : styles.notNavMain}>
                    <div className={styles.navTitle} onClick={goTop}>
                        <h2 className={styles.navMatheus}>Matheus</h2>
                        <h3 className={styles.navRamazza}>Ramazza</h3>
                    </div>
                    <div className={styles.navButton} onClick={handleButtonClick}>
                        <FontAwesomeIcon className={styles.navIcon} icon={faBars} />
                    </div>
                </div>
                <nav className={isButtonClicked ? styles.mobileNav : styles.NotMobileNav}>
                    <ul className={styles.mobileUl}>
                        <li className={styles.mobileLi} onClick={goTop}>Início</li>
                        <li className={styles.mobileLi} onClick={() => {goAbout();}}>Sobre</li>
                        <li className={styles.mobileLi} onClick={goProjects}>Projetos</li>
                        <li className={styles.mobileLi} onClick={goBot}>Contato</li>
                    </ul>
                </nav> 
            </div>

            <main className={styles.main}>
                <div className={styles.title} onClick={goTop}>
                    <h2 className={styles.matheus}>Matheus</h2>
                    <h3 className={styles.ramazza}>Ramazza</h3>
                </div>
                <div className={styles.button} onClick={handleButtonClick}>
                    <FontAwesomeIcon className={styles.icon} icon={faBars} />
                </div>
                <nav className={styles.nav}>
                    <ul className={styles.ul}>
                        <li className={styles.li} onClick={goTop}>Início</li>
                        <li className={styles.li}><Link to="about" spy={true} smooth={true} duration={1000} offset={-60}>Sobre</Link></li>
                        <li className={styles.li}><Link to="projects" spy={true} smooth={true} duration={1000} offset={-70}>Projetos</Link></li>
                        <li className={styles.li} onClick={goBot}>Contato</li>
                    </ul>
                </nav>
            </main>
        </>
    );

}

export default Header;