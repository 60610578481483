import React from "react";
import styles from './styles.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer() {

    return(
        <>
            <section className={styles.footerWrapper}>
                <h1 className={styles.h1}>Copyright © 2024. Todos os direitos reservados</h1>
                <div className={styles.contact}>
                    <a href="https://github.com/Ramazza" target="blank">
                        <FontAwesomeIcon className={styles.icon} icon={faGithub}/>
                    </a>
                    <a href="https://www.linkedin.com/in/matheus-ramazza/" target="blank">
                        <FontAwesomeIcon className={styles.icon} icon={faLinkedin}/>
                    </a>
                </div>
            </section>
        </>
    );

}

export default Footer;